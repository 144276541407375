import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "cardTokenInput", "cardInput", "cardError", "stripePublishableKey", "submit", "clientSecret", "setupIntentInput" ]

  connect() {
    const form = this.element

    const publishableKey = this.stripePublishableKeyTarget.value

    const stripe = Stripe(publishableKey)
    const elements = stripe.elements()

    const clearCardError = () => this.cardErrorTarget.classList.add('hidden')

    var style = {
      base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
              color: '#aab7c4'
          }
      },
      invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
      }
  };

    const cardElement = elements.create('card', { style })
    cardElement.mount(this.cardInputTarget)
    cardElement.on('change', clearCardError)

    form.addEventListener('submit', async e => {
      e.preventDefault()
      this.submitTarget.setAttribute('disabled', '')

      //
      // Attempt to tokenize the card number
      //
      const result = await stripe.createToken(cardElement)

      if (result.error) {
        //
        // On error, display the error message.
        //
        console.log('got error!', result.error)
        this.cardErrorTarget.classList.remove('hidden')
        this.cardErrorTarget.textContent = result.error.message
        this.submitTarget.removeAttribute('disabled')
      } else {
        //
        // On success, add the token to the hidden field and submit to the server
        //
        console.log('got token!', result.token)
        this.cardTokenInputTarget.value = result.token.id
      }

      const clientSecret = this.clientSecretTarget.value
      const name = this.nameTarget.value

      // Create payment method and confirm setup intent.
      stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name,
          },
        }
      }).then((result) => {
        if(result.error) {
          console.log(result)
          console.log('got error!', result.error)
          this.cardErrorTarget.classList.remove('hidden')
          this.cardErrorTarget.textContent = result.error.message
          this.submitTarget.removeAttribute('disabled')
        } else {
          this.setupIntentInputTarget.value = result.setupIntent.id
        }
      });

      form.submit()
    })
  }
}