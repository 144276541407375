import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "price" ]

  connect() {
    if (this.inputTarget.checked === true) {
      this.priceTarget.classList.remove("hidden");
    }
  }

  toggle(event) {
    this.priceTarget.classList.toggle("hidden");
  }
}