// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails, { $ } from "@rails/ujs"
// import "@hotwired/turbo-rails"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "css/application.scss"
import SlimSelect from 'slim-select'
import flatpickr from "flatpickr"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

document.addEventListener("turbolinks:load", () => {

  flatpickr(".datepicker", {})

  tippy('[data-tippy-content]');

  const selects = document.querySelectorAll('.slim-select')
  selects.forEach((selectElement) => {
    new SlimSelect({
      select: selectElement
    })
  })


  // Create an instance of Elements.
  const key = document.querySelectorAll("meta[name='stripe-key']")[0].content
  var stripe = Stripe(key);
  var elements = stripe.elements();

  var style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  // Create an instance of the card Element.
  var card = elements.create('card', { style: style });

  // Add an instance of the card Element into the `card-element` <div>.
  if (document.getElementById('card-element')) {
    card.mount('#card-element');
  }

  // Handle real-time validation errors from the card Element.
  card.addEventListener('change', function (event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  function stripeTokenHandler(intent) {
    // Insert the token ID into the form so it gets submitted to the server

    var hiddenInput = document.getElementById("setup_intent");
    hiddenInput.value = intent;
    // var hiddenInput = document.createElement('input');
    // hiddenInput.setAttribute('type', 'hidden');
    // hiddenInput.setAttribute('name', 'setupIntent');
    // hiddenInput.setAttribute('value', intent);
    // form.appendChild(hiddenInput);

    form.submit();
  }

  // Handle form submission.
  var form = document.getElementById('new_user');

  var userEmail = document.getElementById('user_email');
  if (typeof (form) != 'undefined' && form != null) {
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      // var cardButton = document.getElementById('submit-button');
      // var clientSecret = cardButton.dataset.secret;
      var clientSecret = document.getElementById("clientSecret").value
      stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: card,
            billing_details: {
              email: userEmail.value,
            },
          }
        }
      ).then(function (result) {
        if (result.error) {
          // Display error in UI
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // The setup has succeeded.
          stripeTokenHandler(result.setupIntent.id);
        }
      });

    });
  }


})

import "controllers"
