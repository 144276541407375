import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "title", "body"]

  connect() {
    console.log("Modal Controller");
  }

  showModal(event) {
    this.modalTarget.classList.remove("hidden")
    this.titleTarget.innerText = event.currentTarget.dataset.title
    this.bodyTarget.innerText = event.currentTarget.dataset.message
  }

  closeModal() {
    this.modalTarget.classList.add("hidden")
    this.titleTarget.innerText = ""
    this.bodyTarget.innerText = ""
  }
}