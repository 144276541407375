import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "source", 
    "addressLineOne", 
    "addressLineTwo", 
    "city", 
    "county", 
    "postcode", 
    "contactName", 
    "contactNumber" 
  ]

  copy(event) {
    const dataset = event.target.options[event.target.selectedIndex].dataset
    this.addressLineOneTarget.value = dataset["addressLine-1"] ?? ""
    this.addressLineTwoTarget.value = dataset["addressLine-2"] ?? ""
    this.cityTarget.value = dataset["city"] ?? ""
    this.countyTarget.value = dataset["county"] ?? ""
    this.postcodeTarget.value = dataset["postcode"] ?? ""
    this.contactNameTarget.value = dataset["contactName"] ?? ""
    this.contactNumberTarget.value = dataset["contactNumber"] ?? ""
  }
}