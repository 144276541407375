import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "span", "radio" ]

  connect() {
    this.spanTarget.textContent = this.capitalizeFirstLetter(this.radioTargets.find(c=>c.checked).value)
  }

  change(event) {
    console.log(this.capitalizeFirstLetter(event.target.value))
    this.spanTarget.textContent = this.capitalizeFirstLetter(event.target.value)
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}